import axios from 'axios';

//export const serverUrl = 'https://7474.integ.crawlo.com';
export const serverUrl = 'https://7474.integ.crawlo.com';

class StatsService {
    private instance = axios.create({
        baseURL: serverUrl,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        // other custom settings
        timeout: 1000 * 60 * 60
    });
    StatsService() {
        this.instance = axios.create({
            baseURL: serverUrl,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            // other custom settings
            timeout: 1000 * 60 * 60
        });
    }
    async tasksStats(size = 20, from = 0) {
        const response = await this.instance
            .get(`/provider/tasks`, {
                params: {
                    size,
                    from
                }
            })
            .then((res) => {
                return { data: res.data, error: null };
            })
            .catch((err) => {
                return { error: err.message, data: null };
            });

        if (!response) throw new Error('Somthing went wrong');

        return response;
    }
}

export default StatsService;
