import { AppBar, Toolbar, Box, IconButton, Link, Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import Logo from 'components/common/Logo';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userLoggingOut } from 'store/slices/authSlice';
import { Link as RouterLink } from 'react-router-dom';

interface NewHeaderProps {
    sx: object;
    customClass?: string;
}

function Header({ sx, customClass }: NewHeaderProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(userLoggingOut());
        navigate('/');
    };

    return (
        <AppBar sx={sx} elevation={0} className={customClass}>
            <Toolbar>
                <Box component="div" sx={{ flexGrow: 1, width: '280px' }}>
                    <Logo width="200px" />
                </Box>
                <Link component={RouterLink} to="/tasks" underline="hover" sx={{ marginRight: '5px' }}>
                    <Button variant="contained" color="primary">
                        📃 Tasks
                    </Button>
                </Link>
                <IconButton onClick={handleLogout}>
                    <LogoutIcon fontSize="small" />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}
export default Header;
